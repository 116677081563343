import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a href="/poradnik/katar/pokonaj_zapalenie_zatok/" className="sel">
              {" "}
              &gt; Pokonaj zapalenie zatok
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_181681637.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Pokonaj zapalenie zatok</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <b>Silny ucisk na wysokości czoła, długo utrzymujący się</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>
                      katar, ropna wydzielina z nosa, wysoka gorączka – to
                      podstawowe objawy towarzyszące zapaleniu zatok, z którym
                      zmagają
                    </b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>się</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>miliony Polaków</b>
                  </span>
                  <span className="s3">
                    <b>
                      <sup>1</sup>
                    </b>
                  </span>
                  <span className="s1">
                    <b> (często nie rozpoznając dolegliwości).&nbsp;</b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Zatoki przynosowe (powietrzne jamy w obrębie czaszki)
                    zapewniają
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">ochronę</span>
                  <span className="s2"> </span>
                  <span className="s1">cieplną</span>
                  <span className="s2"> </span>
                  <span className="s1">mózgu i oczodołów, są</span>
                  <span className="s2"> </span>
                  <span className="s1">odpowiedzialne za prawidłową</span>
                  <span className="s2"> </span>
                  <span className="s1">emisję</span>
                  <span className="s2"> </span>
                  <span className="s1">głosu (przewodzą</span>
                  <span className="s2"> </span>
                  <span className="s1">i odbierają</span>
                  <span className="s2"> </span>
                  <span className="s1">dźwięki, modulują</span>
                  <span className="s2"> </span>
                  <span className="s1">głos) oraz pełnią</span>
                  <span className="s2"> </span>
                  <span className="s1">funkcję</span>
                  <span className="s2"> </span>
                  <span className="s1">oddechową</span>
                  <span className="s2"> </span>
                  <span className="s1">(ogrzewają, oczyszczają</span>
                  <span className="s2"> </span>
                  <span className="s1">i nawilżają</span>
                  <span className="s2"> </span>
                  <span className="s1">wdychane powietrze). Łączą</span>
                  <span className="s2"> </span>
                  <span className="s1">się</span>
                  <span className="s2"> </span>
                  <span className="s1">z jamą</span>
                  <span className="s2"> </span>
                  <span className="s1">nosową</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    naturalnymi otworami w jej bocznej ścianie. Gdy zatoki
                    zostają
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    zablokowane i śluz nie może swobodnie spływać, dochodzi do
                    zaburzenia ich funkcjonowania, pojawia się
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    ból i rozpoczyna{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      stan zapalny
                    </a>
                    . &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Zapalenie zatok jest spowodowane zakażeniem bakteryjnym.
                    Bakterie stopniowo blokują
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    połączenia jamy nosowej z zatokami, powodują
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    ich obrzęk i powstanie podciśnienia w okolicy czoła, które
                    uszkadza{" "}
                    <a href="/slowniczek/#blona_sluzowa_nosa" target="_blank">
                      śluzówkę
                    </a>
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">nosa. By zmniejszyć</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    ryzyko zapalenia zatok, warto przestrzegać
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    kilku zasad. W trakcie kataru bardzo ważne jest usuwanie{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzieliny
                    </a>{" "}
                    z zablokowanego nosa (jej zaleganie blokuje narządy
                    połączone z ujściem jamy nosowej). Nie mniej istotne jest
                    unikanie zanieczyszczeń
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    z powietrza (np. dymu papierosowego, szkodliwych związków
                    chemicznych oraz pyłów), które mogą
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">wywołać</span>
                  <span className="s2"> </span>
                  <span className="s1">podrażnienia. Należy pamiętać</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    także o odpowiedniej wilgotności powietrza, regularnym
                    oczyszczaniu filtrów w klimatyzatorach, piciu dużej ilości
                    płynów i unikaniu substancji uczulających (reakcję
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">alergiczną</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    najczęściej wywołują: jesion, lipa, olcha i leszczyna).
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    &nbsp;
                    <img
                      alt="Domowe sposoby, czy lek na zatoki z apteki."
                      src="/web/kcfinder/uploads/images/shutterstock_575462908.jpg"
                      style={{
                        width: "380px",
                        height: "253px",
                        float: "left",
                        margin: "5px 10px 10px 0px",
                        "border-width": "2px",
                        "border-style": "solid",
                      }}
                    />
                    <span className="s1">
                      Na zapchane i bolące zatoki pomocne są
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">również</span>
                    <span className="s2"> </span>
                    <span className="s1">domowe sposoby. Ulgę</span>
                    <span className="s2"> </span>
                    <span className="s1">
                      przynosi kompres z soli jodowo-bromowej –
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">
                      przyśpiesza proces oczyszczania nosa i zatok. W wielu
                      domach stosuje się
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">też</span>
                    <span className="s2"> </span>
                    <span className="s1">
                      maści rozgrzewające, okłady z ciepłego grochu czy gorczycy
                      oraz wdychanie naparu z cebuli, czosnku i świeżo startego
                      chrzanu (w celu wymuszenia kichania, a tym samym
                      ułatwieniu oczyszczania nosa i zatok). Wśród naturalnych
                      metod walki z zapaleniem zatok znajdują
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">się</span>
                    <span className="s2"> </span>
                    <span className="s1">też</span>
                    <span className="s2"> </span>
                    <span className="s1">inhalacje z dodatkiem ziół</span>
                    <span className="s2"> </span>
                    <span className="s1">
                      i olejków eterycznych. Napary łagodzą
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">ból i rozrzedzają</span>
                    <span className="s2"> </span>
                    <span className="s1">wydzielinę, którą</span>
                    <span className="s2"> </span>
                    <span className="s1">
                      dzięki temu można skutecznie usunąć. Jednak nie
                      zmniejszają
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">
                      one przekrwienie błony śluzowej, ponieważ
                    </span>
                    <span className="s2"> </span>
                    <span className="s1">działają</span>
                    <span className="s2"> </span>
                    <span className="s1">tylko napotnie.</span>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Domowe sposoby na pokonanie kataru warto poszerzyć o
                  odpowiednie preparaty, takie jak tabletki lub areozole marki{" "}
                  <a href="/produkty/" target="_blank">
                    Sudafed<sup>®</sup>
                  </a>
                  .{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed<sup>®</sup> Xylospray HA
                  </a>{" "}
                  dzięki zawartości{" "}
                  <a href="/slowniczek/#ksylometazolina" target="_blank">
                    ksylometazoliny
                  </a>
                  , obkurcza naczynia krwionośne w błonie śluzowej nosa i
                  zmniejsza obrzęk, ułatwiając oddychanie. Co więcej,{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed<sup>®</sup> Xylospray HA
                  </a>{" "}
                  nie zawiera konserwantów, dzięki czemu nie wywołuje podrażnień
                  i jako jedyny<sup>2</sup> posiada aż trzy substancje
                  nawilżające nos: <a href="/slowniczek/#glicerol">glicerol</a>,{" "}
                  <a href="/slowniczek/#sorbitol">sorbitol</a> i{" "}
                  <a href="/slowniczek/#kwas_hialuronowy" target="_blank">
                    kwas hialuronowy
                  </a>{" "}
                  (w postaci soli sodowej).{" "}
                  <a href="/produkty/sudafed_tabletki/" target="_blank">
                    Sudafed<sup>®</sup> Tabletki
                  </a>{" "}
                  są rozwiązaniem dla osób, które preferują dyskretniejszą formę
                  aplikacji. Dzięki zawartości{" "}
                  <a href="/slowniczek/#pseudoefedryna" target="_blank">
                    pseudoefedryny
                  </a>
                  , zmniejszają obrzęk błony śluzowej nosa i ilość wydzieliny
                  zalegającej w górnych drogach oddechowych, dzięki czemu
                  eliminują uczucie ucisku w głowie i zatokach<sup>3</sup>.
                </p>
                <p>&nbsp;</p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <font color="#226ea1">
                    <font face="Arial, serif">
                      <font size="2">
                        <span className="s1">
                          Gdy zaobserwowane przez nas objawy zapalenia zatok nie
                          mijają lub nasilają się, konieczna jest pomoc
                          laryngologa. Badanie lekarskie pomoże szczegółowo
                          przeanalizować problem, a lekarz podejmie decyzję o
                          dalszym leczeniu.&nbsp;
                        </span>
                      </font>
                    </font>
                  </font>
                </p>
                <div>&nbsp;</div>
                <div>
                  <span
                    className="s3"
                    style={{
                      "font-size": "11px",
                      "font-family": "Arial, serif",
                      color: "rgb(34, 110, 161)",
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    1
                  </span>
                  <span
                    className="s1"
                    style={{
                      "font-size": "11px",
                      "font-family": "Arial, serif",
                      color: "rgb(34, 110, 161)",
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    Mieczkowski Daniel,{" "}
                    <i>Co drugi Polak miał kłopoty z zatokami </i>[online],
                    [dostęp: 15 kwiecień 2016], Dostępny w Internecie:&nbsp;
                    <a href="http://www.nowoczesna-klinika.pl/pl/artykuly/17/Analizy_Raporty/1/284/Co_drugi_Polak_mial_klopoty_z_zatokami">
                      <span className="s4">
                        http://www.nowoczesna-klinika.pl/pl/artykuly/17/Analizy_Raporty/1/284/Co_drugi_Polak_mial_klopoty_z_zatokami
                      </span>
                    </a>
                    &nbsp;
                  </span>
                </div>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    2 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę
                    w postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                    według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                    do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                    stanu na dzień 1 Lutego 2023 r.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    3 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                    <span
                      style={{
                        "font-family": "Arial, serif",
                      }}
                    >
                      &nbsp;
                    </span>
                  </span>
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "small",
                      "font-family": "Arial, serif",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="p1">
                  <font color="#226ea1">
                    <font face="Arial, serif">
                      <font size="2">
                        <span
                          style={{
                            "font-size": "11px",
                          }}
                        >
                          <span className="s1">Bibliografia:</span>
                        </span>
                      </font>
                    </font>
                  </font>
                </p>
                <p className="p1">
                  <font color="#226ea1">
                    <font face="Arial, serif">
                      <font size="2">
                        <span
                          style={{
                            "font-size": "11px",
                          }}
                        >
                          <span className="s1">
                            <i>Choroby ucha, nosa i gardła z chirurgią</i>
                          </span>
                          <span className="s2">
                            <i> </i>
                          </span>
                          <span className="s1">
                            <i>głowy i szyi, </i>opracowanie zbiorowe,{" "}
                            <a href="http://www.empik.com/szukaj/produkt?publisherfacet=wydawnictwo+medyczne+urban+%25252525252526+partner">
                              Wydawnictwo&nbsp;Medyczne&nbsp;Urban&nbsp;&amp;&nbsp;Partner
                            </a>
                            , 2012.
                          </span>
                        </span>
                      </font>
                    </font>
                  </font>
                </p>
                <p className="p1">
                  <font color="#226ea1">
                    <font face="Arial, serif">
                      <font size="2">
                        <span
                          style={{
                            "font-size": "11px",
                          }}
                        >
                          <span className="s1">
                            Mieczkowski Daniel,{" "}
                            <i>Co drugi Polak miał kłopoty z zatokami </i>
                            [online], [dostęp: 15 kwiecień 2016], Dostępny w
                            Internecie:
                            http://www.nowoczesna-klinika.pl/pl/artykuly/17/Analizy_Raporty/1/284/Co_drugi_Polak_mial_klopoty_z_zatokami
                          </span>
                        </span>
                      </font>
                    </font>
                  </font>
                </p>
                <p className="p2">
                  <font color="#226ea1">
                    <font face="Arial, serif">
                      <font size="2">&nbsp;</font>
                    </font>
                  </font>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
